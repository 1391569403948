import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BlockUi from "react-block-ui";

import * as moment from "moment";
import { useTranslation } from "react-i18next";

import { getDashboardPatientActivity } from "../../../store/actions/practiceDashboard";
import L_SupplementActivity from "../../LoadingEffectComponent/Dashboard/Practice/L_SupplementActivity";

function PatientSupplement() {
	// const loading = useSelector(state => state.practiceDashboard.loading);
	const [loading, setLoading] = useState(true);
	const [viewCatelog, setViewCatelog] = useState(false);
	const [viewRecommendations, setRecommendations] = useState(false);
	const navigate = useNavigate();
	// const [loading, setLoading] = useState(true);

	const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
	const currentPractice = useSelector((state) => state.auth.currentPractice);
	const currentUserId = useSelector((state) => state.auth.currentUserId);
	const token = useSelector((state) => state.auth.token);
	const supplement_recommendations = useSelector(
		(state) => state.practiceDashboard.supplement_recommendations,
	);
	const appToken = useSelector((state) => state.auth.appToken);
	const enableECommerce = useSelector((state) => state.auth.enableECommerce);
	const userPermissions = useSelector((state) => state.auth.userPermissions);
	const practitioner_id = useSelector((state) => state.auth.practitioner_id);
	const language = useSelector((state) => state.auth.language);

	const dispatch = useDispatch();
	const getDashboardActivityAction = (
		appToken,
		currentPracticeID,
		currentPractice,
		practitioner_id,
	) =>
		dispatch(
			getDashboardPatientActivity(
				appToken,
				currentPracticeID,
				currentPractice,
				practitioner_id,
			),
		);

	const { t, i18n } = useTranslation();

	useEffect(() => {
		getDashboardActivityAction(
			appToken,
			currentPracticeID,
			currentPractice,
			practitioner_id,
		).then((response) => {
			setLoading(false);
		});
		if (
			!userPermissions?.some((item) =>
				item.hasOwnProperty("ViewingRecommendations"),
			)
		) {
			setRecommendations(true);
		}
		if (
			!userPermissions?.some((item) => item.hasOwnProperty("ViewingTheCatalogue"))
		) {
			setViewCatelog(true);
		}
	}, []);

	let nagivateToSupplements = (e) => {
		navigate("/practice/supplement_catalog", {
			state: {
				path: "/pg_practice_recommendations",
				browseCatalog: false,
			},
		});
	};
	

	let nagivateToOrders = (e) => {
		navigate("/practice/supplement_catalog", {
			state: {
				path: "/script_list",
				browseCatalog: false,
			},
		});
	};

	const timeToWords = (date) => {
		return moment(date).fromNow().toString();
	};

	return (
		<div
			className="pg-card position-relative p-4"
			style={{ minHeight: 830, backgroundColor: "#14328c" }}
		>
			{loading ? (
				<L_SupplementActivity />
			) : (
				<div>
					<div className="pg-heading text-center text-light">{t("pracd_psr")}</div>
					<div className="my-4">
						<div className="pg-text__bold my-1 mb-2 text-white">{t("pracd_ra")}</div>
						{supplement_recommendations?.activities?.length < 1 && (
							<div className="d-block pg-text text-center">
								<div className="text-white">{t("pracd_nodata")}</div>
							</div>
						)}
						{supplement_recommendations?.activities?.map((data, i) => {
							return (
								<div key={i}>
									<div className="d-flex justify-content-between pg-text my-1">
										<div className="text-white pg-text">
											{" "}
											{data["logs"]} {timeToWords(data["date"])}{" "}
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div style={{ borderBottom: "1px solid #fff" }} />
					<div className="pg-text__bold text-white my-1">{t("pracd_stat")}</div>
					<div className="d-flex">
						<div className="pg-heading__count">
							{supplement_recommendations?.stats?.total_supplement_recommendations}
						</div>
						<div className="pg-text__patient">{t("pracd_tsr")}</div>
					</div>
					<div className="d-flex">
						<div className="pg-heading__count">
							{supplement_recommendations?.stats?.total_viewed_recommendations}
						</div>
						<div
							className="pg-text__patient "
							dangerouslySetInnerHTML={{ __html: t("pracd_srwbp") }}
						/>
					</div>
					{(enableECommerce ||
						supplement_recommendations?.stats?.total_ordered_recommendations !=
							0) && (
						<div className="d-flex">
							<div className="pg-heading__count">
								{supplement_recommendations?.stats?.total_ordered_recommendations}
							</div>
							<div className="pg-text__patient ">
								{" "}
								Supplement recommendations <br />
								ordered by patients
							</div>
						</div>
					)}
					<div className="pg-card__btn px-2  w-100">
						<button
							className="btn btn-light my-3 mx-auto d-block "
							onClick={() => nagivateToSupplements()}
							id="view_supplement-btn__dashboard"
							disabled={viewRecommendations}
						>
							{t("pracd_btn_3")}
						</button>
						{language === "en" && <button
							className="btn btn-outline__invert px-5 mx-auto d-block my-2"
							onClick={() => nagivateToOrders()}
							id="view_supplement-btn__dashboard"
							style={{ width: "50%" }}
							disabled={viewRecommendations}
						>
							Order History
						</button>}
						<button
							className="btn btn-outline__invert px-5 mx-auto d-block my-3"
							onClick={() => navigate("/practice/patient/catalog")}
							id="view-catalogue-btn__dashboard"
							style={{ width: "50%" }}
							disabled={viewCatelog}
						>
							{t("pracd_btn_4")}
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
export default PatientSupplement;
